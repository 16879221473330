import { Categories, makeCategory } from "../shared/categories";
import { OutputFuncParams, ProductHardwareOutput } from "./types";
import * as helpers from "../shared/helpers";

/**
 * This mapping is for adding hardware to the CSV that is not included by
 * default in the ClosetPro CSV export.
 */
export const productHardwareMapping = [
  {
    matchNames: ["Adjustable Shelf"],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      if (parseFloat(row.Depth) > 18) {
        return [
          [row.ClosetName, "Shelf Support Metal", 6, "Each", makeCategory(Categories.Hardware)],
        ];
      } else {
        return [
          [row.ClosetName, "Shelf Support Metal", 4, "Each", makeCategory(Categories.Hardware)],
        ];
      }
    },
  },
  {
    matchNames: [
      "Fixed Shelf",
      "Fixed Shelf - Hinge Plate",
      "Fixed Shelf with Puck",
      "Fixed Shelf with Strip",
    ],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      if (parseFloat(row.Depth) > 18) {
        return [
          [row.ClosetName, "Titus Screw-in Dowel", 6, "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(row.Depth, parseInt(row.Quantity)), "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
        ];
      } else {
        return [
          [row.ClosetName, "Titus Screw-in Dowel", 4, "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(row.Depth, parseInt(row.Quantity)), "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
        ];
      }
    },
  },
  {
    matchNames: ["Fixed Shelf - One Sided"],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      if (parseFloat(row.Depth) > 18) {
        return [
          [row.ClosetName, "Titus Screw-in Dowel", 3, "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(row.Depth, parseInt(row.Quantity)) / 2, "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
        ];
      } else {
        return [
          [row.ClosetName, "Titus Screw-in Dowel", 2, "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Titus Cam Fitting", helpers.calculateExtraHardwareFor18Deep(row.Depth, parseInt(row.Quantity)) / 2, "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
        ];
      }
    },
  },
  {
    matchNames: ["Slanted Shoe Shelf"],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Shelf Support Metal", 2, "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Titus Screw-in Dowel", 2, "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Titus Cam Fitting", 2, "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: [
      "Backer",
      "Island Divider",
    ],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Titus Screw-in Dowel", 6, "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Titus Cam Fitting", 6, "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: [
      "Corner Shelf L Shaped - Fixed",
      "Corner Shelf Pie - Fixed",
      "Corner Shelf Radius - Fixed",
    ],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Titus Screw-in Dowel", 6, "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Titus Cam Fitting", 6, "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: [
      "Corner Shelf L Shaped - Adjustable",
      "Corner Shelf Pie - Adjustable",
      "Corner Shelf Radius - Adjustable",
    ],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Shelf Support Metal", 6, "Each", makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: ["Drawer Box"], //@ToDo fix CPS from exporting out hardware for this product
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      if (row.DoorStyle.includes("Endura")) {
        return [
          [row.ClosetName, "Screw #6 x 1/2 Pan Robertson", 12 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Euro Screw", 4 * parseInt(row.Quantity), "Each", helpers.getHardwareColorFromWoodColor(row.Color), makeCategory(Categories.Hardware)],
        ];
      } else {
        return [
          [row.ClosetName, "Wood Dowel", helpers.calculateWoodDowelQty(row.Height, parseInt(row.Quantity)), "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Screw #6 x 1-1/4 Flat Robertson", 10 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Screw #8 x 3/4 Pan Robertson", 6 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Euro Screw", 4 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
          [row.ClosetName, "Screw #8-32 x 1-3/4 Truss Combination", 2 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
        ];
      }
    },
  },
  {
    matchNames: ["File Drawer"], //@ToDo fix CPS from exporting out hardware for this product
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Wood Dowel", helpers.calculateWoodDowelQty(row.Height, parseInt(row.Quantity)), "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Screw #6 x 1-1/4 Flat Robertson", 10 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Screw #8 x 3/4 Pan Robertson", 6 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Euro Screw", 4 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
        [row.ClosetName, "Screw #8-32 x 1-3/4 Truss Combination", 2 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: [
      "Gable - Special Drilling",
      "Corner Gable",
    ],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Screw #8 x 3/4 Pan Robertson", 2 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: ["Sliding Shelf"],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Screw #8 x 3/4 Pan Robertson", 4 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
      ];
    },
  },
  {
    matchNames: [
      "TAG Divided Drawer",
      "TAG Divided Shelf",
      "TAG Folding Station",
      "TAG Jewelry",
      "TAG Laundry Organizer",
      "TAG Lingerie Drawer",
      "TAG Pant Rack",
      "TAG Shoe Organizer",
    ],
    outputFunction: ({ row }: OutputFuncParams): ProductHardwareOutput => {
      // prettier-ignore
      return [
        [row.ClosetName, "Euro Screw", 4 * parseInt(row.Quantity), "Each", makeCategory(Categories.Hardware)],
      ];
    },
  },
];
