import * as helpers from "../shared/helpers";
import * as helpersCPS from "./helpersCPS";
import { OutputFuncParams } from "./types";
import { Categories, makeCategory } from "../shared/categories";

export const productDataMapping = [
  /** Specific Product Category */
  {
    matchNames: [
      "Adjustable Shelf",
      "Flat Shoe Shelf",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Depth,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Backer"],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Height,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Gable"],
    outputFunction: ({ row }: OutputFuncParams) => {
      const railNotch = row.RailNotch === "N" ? "0" : "1";
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Height,
        row.Depth,
        railNotch,
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Shelf L Shaped - Adjustable"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        "6",
        "Right",
        row.Quantity,
        row.CornerWidthL,
        row.CornerWidthR,
        row.CornerDepthL,
        row.CornerDepthR,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Shelf L Shaped - Fixed"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        "6",
        "Right",
        row.Quantity,
        row.CornerWidthL,
        row.CornerWidthR,
        row.CornerDepthL,
        row.CornerDepthR,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Shelf Pie - Adjustable"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        "6",
        "Right",
        row.Quantity,
        row.CornerWidthL,
        row.CornerWidthR,
        row.CornerDepthL,
        row.CornerDepthR,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Shelf Pie - Fixed"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        "6",
        "Right",
        row.Quantity,
        row.CornerWidthL,
        row.CornerWidthR,
        row.CornerDepthL,
        row.CornerDepthR,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Shelf Radius - Adjustable"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        "6",
        "Right",
        row.Quantity,
        row.CornerWidthL,
        row.CornerWidthR,
        row.CornerDepthL,
        row.CornerDepthR,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Corner Shelf Radius - Fixed"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        "6",
        "Right",
        row.Quantity,
        row.CornerWidthL,
        row.CornerWidthR,
        row.CornerDepthL,
        row.CornerDepthR,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Drawer Box"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      const stringHeight = row.DoorStyle.includes("Endura")
        ? helpers.getDrawerFaceInfo(row.Height).simplifiedEnduraBoxHeight
        : helpers.getDrawerFaceInfo(row.Height).drawerFaceHeight;
      const newDepth = (row.DoorStyle.includes("Endura") && row.Depth === "24") ? "22" : row.Depth;
      return [
        row.ClosetName,
        row.DoorStyle,
        helpersCPS.getDrawerWoodType(row.DoorStyle),
        helpersCPS.getDrawerEdgeTapeType(row.DoorStyle),
        row.SlideType,
        row.PartExport.split(" - ")[0],
        "Center",
        row.Quantity,
        newDepth, //@ToDo Check to make sure works
        stringHeight,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["File Drawer"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      const newDoorStyle = row.DoorStyle.includes("Endura") ? "Melamine Box - Side Mount" : row.DoorStyle;
      return [
        row.ClosetName,
        newDoorStyle,
        helpersCPS.getDrawerWoodType(newDoorStyle),
        helpersCPS.getDrawerEdgeTapeType(newDoorStyle),
        " ",
        row.PartExport.split(" - ")[0],
        "Center",
        row.Quantity,
        row.Depth,
        helpers.getDrawerFaceInfo(row.Height).drawerFaceHeight,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Filler"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        "Filler",
        row.Color,
        "Board Only",
        row.Quantity,
        row.Height,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: [
      "Fixed Shelf",
      "Bottom Fixed Shelf",
      "Fixed Flat Shoe Shelf",
      "Fixed Shelf - BB Notch",
      "Top Fixed Shelf",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Depth,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Fixed Shelf - Hinge Plate"], // Create Function that Exports this @ToDo
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Depth,
        row.Width,
        "", //Door Style - Single Top or Single Bottom,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Fixed Shelf - One Sided"],
    outputFunction: ({ row }: OutputFuncParams) => {
      const sideForVBs = row.CutToFit === "L" ? "Left" : row.CutToFit === "R" ? "Right" : null;
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Depth,
        row.Width,
        sideForVBs,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Fixed Shelf with Puck"], //Future, Done for Now
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Depth,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Fixed Shelf with Strip"], //Future, Done for Now
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        row.Quantity,
        row.Depth,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  // {
  //   matchNames: ["Gable - Special Drilling"], //Done for Full Line Drilling
  //   outputFunction: ({ row }: OutputFuncParams) => {
  //     const railNotch = parseFloat(row.Elevation) > 0 ? "1" : "0";
  //     const topDownDrillingLeft =
  //       row.APILeft === "None"
  //         ? ["T", "R"].includes(row.VertHand)
  //           ? row.Height
  //           : "0"
  //         : "0";
  //     const topDownDrillingRight =
  //       row.APIRight === "None"
  //         ? ["T", "L"].includes(row.VertHand)
  //           ? row.Height
  //           : "0"
  //         : "0";
  //     const slideTypeForGables =
  //       row.SlideType.split("|")[0].trim() === ""
  //         ? row.SlideType.split("|")[1].trim()
  //         : row.SlideType.split("|")[0].trim();
  //     return [
  //       row.ClosetName,
  //       row.PartExport,
  //       row.Color,
  //       row.EBColor,
  //       slideTypeForGables,
  //       row.Quantity,
  //       row.Height,
  //       row.Depth,
  //       railNotch,
  //       helpers.firstFiveChars(row.ClosetName),
  //       row.WallNum,
  //       row.SectionNum,
  //       row.APILeft, //CD Patern Left,
  //       topDownDrillingLeft, //Top Down Height Left,
  //       row.BoreL, //Top Down Depth Left,
  //       "0", //Bottom Up Height Left,
  //       "0", //Bottom Up Depth Left,
  //       "0", //DWR Bank Height Left,
  //       "0", //DWR Depth Left,
  //       row.APIRight, //CD Patern Right,
  //       topDownDrillingRight, //Top Down Height Right,
  //       row.BoreR, //Top Down Depth Right,
  //       "0", //Bottom Up Height Right,
  //       "0", //Bottom Up Depth Right,
  //       "0", //DWR Bank Height Right,
  //       "0", //DWR Depth Right,
  //       "0", //TKN Height,
  //       "0", //TKN Depth,
  //       row.BBHeight, //BBN Height,
  //       row.BBDepth, //BBN Depth,
  //       row.BackingL, //Backing Left,
  //       row.BackingR, //Backing Right,
  //       "0", //Lighting Left,
  //       "0", //Lighting Right,
  //       makeCategory(Categories.Wood),
  //     ];
  //   },
  // },
  {
    matchNames: ["Gable - Special Drilling"], //Done for Line Drilling
    outputFunction: ({ row }: OutputFuncParams) => {
      const railNotch = parseFloat(row.Elevation) > 0 ? "1" : "0";
      const slideTypeForGables = row.SlideType.split("|")[0].trim() === "" ? row.SlideType.split("|")[1].trim() : row.SlideType.split("|")[0].trim();
      const drillingDataLeft = helpersCPS.currentGableAndOpposingGableLeft(row);
      const drillingDataRight = helpersCPS.currentGableAndOpposingGableRight(row);
      const topDownHeightLeft = row.APILeft === "None" ? drillingDataLeft.topDownHeightLeft : 0;
      const topDownDepthLeft = drillingDataLeft.topDownDepthLeft;
      const bottomUpHeightLeft = drillingDataLeft.bottomUpHeightLeft;
      const bottomUpDepthLeft = drillingDataLeft.bottomUpDepthLeft;
      const topDownHeightRight = row.APIRight === "None" ? drillingDataRight.topDownHeightRight : 0;
      const topDownDepthRight = drillingDataRight.topDownDepthRight;
      const bottomUpHeightRight = drillingDataRight.bottomUpHeightRight;
      const bottomUpDepthRight = drillingDataRight.bottomUpDepthRight;
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.EBColor,
        slideTypeForGables,
        row.Quantity,
        helpers.findStandardGableSize(parseFloat(row.Height)),
        row.Depth,
        railNotch,
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        row.APILeft, //CD Patern Left,
        helpers.findStandardGableSize(topDownHeightLeft), //Top Down Height Left,
        topDownDepthLeft, //Top Down Depth Left,
        helpers.findStandardGableSize(bottomUpHeightLeft), //Bottom Up Height Left,
        bottomUpDepthLeft, //Bottom Up Depth Left,
        "0", //DWR Bank Height Left,
        "0", //DWR Depth Left,
        row.APIRight, //CD Patern Right,
        helpers.findStandardGableSize(topDownHeightRight), //Top Down Height Right,
        topDownDepthRight, //Top Down Depth Right,
        helpers.findStandardGableSize(bottomUpHeightRight), //Bottom Up Height Right,
        bottomUpDepthRight, //Bottom Up Depth Right,
        "0", //DWR Bank Height Right,
        "0", //DWR Depth Right,
        "0", //TKN Height,
        "0", //TKN Depth,
        row.BBHeight, //BBN Height,
        row.BBDepth, //BBN Depth,
        row.BackingL, //Backing Left,
        row.BackingR, //Backing Right,
        "0", //Lighting Left,
        "0", //Lighting Right,
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Island Divider"], // Not Done @todo
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        row.Color,
        row.Quantity,
        row.Height,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Hang Rod"],
    outputFunction: ({ row }: OutputFuncParams) => {
      const finishColor = row.Color === "Matte Black" ? "Black" : row.Color;
      return [
        row.ClosetName,
        row.PartExport, //Product Name for Poles or Wardrobe Tubes
        row.Quantity,
        row.Width,
        finishColor,
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.PolesAndCups),
      ];
    },
  },
  {
    matchNames: ["Island Gable - Double"], //Not Done @todo
    outputFunction: ({ row }: OutputFuncParams) => {
      const islandDepthFront = row.BoreL === "0" ? row.BoreR : row.BoreL;
      const islandDepthBack = parseFloat(row.Depth) - parseFloat(islandDepthFront) - 0.75;
      const slideTypeForGables = row.SlideType.split("|")[0].trim() === "" ? row.SlideType.split("|")[1].trim() : row.SlideType.split("|")[0].trim();
      const islandFinishedLeft = (row.LineBore1StartStopL === "" && row.LineBore2StartStopL === "") === true ? 1 : 0;
      const islandFinishedRight = (row.LineBore1StartStopR === "" && row.LineBore2StartStopR === "") === true ? 1 : 0;
      return [
        row.ClosetName,
        "Island Gable - Double",
        row.Color,
        row.Color,
        slideTypeForGables,
        row.Quantity,
        row.Height,
        islandDepthFront,
        islandDepthBack,
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        islandFinishedLeft, //Finished Left,
        "0", //DWR Bank Front Left,
        "0", //DWR Bank Back Left,
        islandFinishedRight, //Finished Right,
        "0", //DWR Bank Front Right,
        "0", //DWR Bank Back Right,
        "0", //TKN Height,
        "0", //TKN Depth,
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Kick"], //Done
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartExport,
        row.Color,
        "Horizontal",
        row.Quantity,
        row.PartName.replace("Toe Kick ", ""),
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Miscellaneous"] /** @todo Do some testing! */,
    outputFunction: ({ row }: OutputFuncParams) => {
      const sizes = [
        parseFloat(row.Height),
        parseFloat(row.Width),
        parseFloat(row.Depth),
      ];
      const nonZeroSizes = sizes.filter((size) => !isNaN(size) && size !== 0);
      const largeSize = Math.max(...nonZeroSizes);
      const smallSize = Math.min(...nonZeroSizes);
      const nonZeroSmallSize = nonZeroSizes.length > 0 ? smallSize : 0;
      return [
        row.ClosetName,
        "Miscellaneous",
        row.Color,
        row.EBColor,
        "Length",
        row.Quantity,
        largeSize,
        nonZeroSmallSize,
        helpersCPS.getTapingInstructions(
          row.EBFront,
          row.EBBack,
          row.EBTop,
          row.EBBottom,
          row.Height,
          row.Width,
          row.Depth
        ),
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Door"], // Almost Done @ToDo
    outputFunction: ({ row }: OutputFuncParams) => {
      const grainDirectionMap = new Map([
        ["Shaker", "Shaker Door"],
        ["Terzetto", "Terzetto"],
        ["Euro Slab", "Vertical"],
      ]);
      const newProductName = row.DoorStyle +
        (["Clear Glass", "Frosted Glass", "Mirror"].includes(row.DoorInsert)
          ? " Glass Door"
          : " Door");
      const newPanelMaterial = [
        "Clear Glass",
        "Frosted Glass",
        "Mirror",
      ].includes(row.DoorInsert)
        ? row.DoorInsert
        : row.DoorStyle.trim() === "Shaker"
          ? row.Color
          : null;
      return [
        row.ClosetName,
        newProductName,
        row.Color,
        newPanelMaterial,
        row.Color,
        grainDirectionMap.get(row.DoorStyle),
        row.Quantity,
        row.Height,
        row.Width,
        helpersCPS.getDoorOpeningStyle(row.HingeLocation),
        row.PartExport.split(" - ")[0],
        "Select One", // Handle Placement we still need this info our of CPS @ToDo
        "2.75",
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.Wood),
      ].filter((i) => i);
    },
  },
  {
    matchNames: ["Drawer Front"],
    outputFunction: ({ row, allRows }: OutputFuncParams) => {
      const grainDirectionMap = new Map([
        ["Shaker", "Shaker Drawer"],
        ["Terzetto", "Terzetto"],
        ["Euro Slab", "Horizontal"],
      ]);
      return [
        row.ClosetName,
        row.DoorStyle + " Drawer Face",
        row.Color,
        row.DoorStyle.trim() == "Shaker" ? row.Color : null,
        row.Color,
        grainDirectionMap.get(row.DoorStyle),
        "Center",
        row.Quantity,
        helpers.getDrawerFaceInfo(row.Height).drawerFaceHeight,
        row.Width,
        row.PartExport.split(" - ")[0],
        helpersCPS.getBoxType(row, allRows),
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ].filter((i) => i);
    },
  },
  {
    matchNames: ["Slanted Shoe Shelf"],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        "Slanted Shoe Shelf",
        row.Color,
        row.EBColor,
        "TAG Fence",
        row.Quantity,
        row.Depth,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Sliding Shelf"], //Future, Done for Now
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        "Sliding Shelf",
        row.Color,
        row.EBColor,
        row.SlideType, //Export out Glide Details
        row.Quantity,
        row.Depth,
        row.Width,
        helpers.firstFiveChars(row.ClosetName),
        makeCategory(Categories.Wood),
      ];
    },
  },
  {
    matchNames: ["Hang Rail"],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        "Wall Rail & Wall Rail Cover",
        row.Quantity,
        "93",
        helpers.getHardwareColorFromWoodColor(row.Color),
        helpers.firstFiveChars(row.ClosetName),
        row.WallNum,
        row.SectionNum,
        makeCategory(Categories.Hardware),
      ];
    },
  },
  {
    matchNames: [
      "Left Cup",
      "Right Cup",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      const finishColor = row.Color === "Matte Black" ? "Black" : row.Color;
      return [
        row.ClosetName,
        row.PartExport,
        row.Quantity,
        finishColor,
        makeCategory(Categories.PolesAndCups),
      ];
    },
  },
  {
    matchNames: ["Elite Shoe Fence"],
    outputFunction: ({ row }: OutputFuncParams) => {
      const attributes = helpers.getAttributesFromKCDProductName(row.PartExport);
      const finish = helpers.getFinishName(attributes?.finish as string);
      const widthFinish = `${row.Height}" - ${finish}`;
      return [row.ClosetName, row.PartName, row.Quantity, widthFinish, makeCategory(Categories.Accessories),];
    },
  },
  /**Engage Items */
  {
    matchNames: [
      "TAG Divided Drawer",
      "TAG Divided Shelf",
      "TAG Folding Station",
      "TAG Jewelry",
      "TAG Laundry Organizer",
      "TAG Lingerie Drawer",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      const newTAGNames = new Map([
        ["TAG Divided Drawer", "Engage Divided Deep Drawer"],
        ["TAG Divided Shelf", "Engage Divided Shelf"],
        ["TAG Folding Station", "Engage Folding Station"],
        ["TAG Jewelry", "Engage Jewelry Organizer"],
        ["TAG Laundry Organizer", "Engage Laundry Organizer"],
        ["TAG Lingerie Drawer", "Engage Lingerie Drawer"],
      ]);
      const attributes = helpers.getAttributesFromKCDProductName(row.PartExport);
      const fabric = helpers.getFabricName(attributes?.fabric as string);
      const finish = helpers.getFinishName(attributes?.finish as string);
      const widthFabricFinish = `${row.Width}" - ${fabric} - ${finish}`;
      return [
        row.ClosetName,
        newTAGNames.get(row.PartName),
        row.Quantity,
        widthFabricFinish,
        makeCategory(Categories.Accessories),
      ];
    },
  },
  {
    matchNames: [
      "TAG Pant Rack",
      "TAG Shoe Organizer",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      const newTAGNames = new Map([
        ["TAG Pant Rack", "Engage Pant Organizer"],
        ["TAG Shoe Organizer", "Engage Shoe Organizer"],
      ]);
      const attributes = helpers.getAttributesFromKCDProductName(row.PartExport);
      const finish = helpers.getFinishName(attributes?.finish as string);
      const widthFinish = `${row.Width}" - ${finish}`;
      return [
        row.ClosetName,
        newTAGNames.get(row.PartName),
        row.Quantity,
        widthFinish,
        makeCategory(Categories.Accessories),
      ];
    },
  },
  /**Symphony Items */
  {
    matchNames: [
      "Symphony Wall Organizer - 19in.",
      "Symphony Wall Organizer - 42in.",
      "Symphony Wall Organizer - 66in.",
      "Symphony Wall Organizer Duet - 7.5in",
      "Symphony Wall Organizer Duet Surface - 12.5in",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      const newTAGNames = new Map([
        ["Symphony Wall Organizer - 19in.", "Symphony QUARTET"],
        ["Symphony Wall Organizer - 42in.", "Symphony OCTAVE"],
        ["Symphony Wall Organizer - 66in.", "Symphony DODECA"],
        ["Symphony Wall Organizer Duet - 7.5in", "Symphony DUET"],
        ["Symphony Wall Organizer Duet Surface - 12.5in", "Symphony DUET Surface Mount"],
      ]);
      const symphonyPackQty = new Map([
        ["Symphony Wall Organizer - 19in.", "(4 pack)"],
        ["Symphony Wall Organizer - 42in.", "(8 pack)"],
        ["Symphony Wall Organizer - 66in.", "(12 pack)"],
        ["Symphony Wall Organizer Duet - 7.5in", "(2 pack)"],
        ["Symphony Wall Organizer Duet Surface - 12.5in", "(2 pack)"],
      ]);
      const attributes = helpers.getAttributesFromKCDProductName(row.PartExport);
      const leather = helpers.getLeatherName(attributes?.leather as string);
      const finish = helpers.getFinishName(attributes?.finish as string);
      const newHeight = row.Height === "12.5" ? "12" : (row.Height === "7.5" ? "7" : row.Height);
      const heightFinish = `${newHeight}" - ${finish}`;
      const newWidth = row.Width === "13.5" ? "13" : row.Width;
      const widthLeather = `${newWidth}" - ${leather} ${symphonyPackQty.get(row.PartName)}`;
      return [
        row.ClosetName,
        newTAGNames.get(row.PartName),
        row.Quantity,
        heightFinish,
        widthLeather,
        makeCategory(Categories.Accessories),
      ];
    },
  },
  {
    matchNames: ["Symphony Wall Organizer Solo - 1.5in"],
    outputFunction: ({ row }: OutputFuncParams) => {
      const attributes = helpers.getAttributesFromKCDProductName(row.PartExport);
      const leather = helpers.getLeatherName(attributes?.leather as string);
      const finish = helpers.getFinishName(attributes?.finish as string);
      const newWidth = row.Width === "13.5" ? "13" : row.Width;
      const widthFinishLeather = `${newWidth}" - ${finish} - ${leather}`;
      return [
        row.ClosetName,
        "Symphony SOLO",
        row.Quantity,
        widthFinishLeather,
        makeCategory(Categories.Accessories),
      ];
    },
  },
  /**Accessories Category */
  {
    matchNames: [
      '14" Utility Hook',
      '16" Utility Hook',
      '5" Utility Hook',
      "Activity Rack",
      "Belt Holder",
      "Belt Hook",
      "Bike Hook",
      "Bracelet Holder",
      "Broom Hook",
      "Clear Storage Bins",
      "Coat Holder",
      "Coat Hook",
      "Compact Coat Hook",
      "Cover Cap - Heavy Duty",
      "Cover Cap - Light Duty",
      "Designer Bag with ELITE Display",
      "Designer Bag with SIGNATURE Display",
      "Designer Bag without Display",
      "Double Holder",
      "Double Hook",
      "Double Wall Hook",
      "Drawer Slide Spacer",
      "Edge Mounting Tape",
      "Edge Profile",
      "Elite Belt Rack",
      'Elite Fixed Mirror - 35"',
      'Elite Fixed Mirror - 47"',
      "Elite Scarf Rack",
      //"Elite Shoe Fence - Corner Post",
      'Elite Sliding Full Rotation Mirror - 35"',
      'Elite Sliding Full Rotation Mirror - 47"',
      "Elite Tie Rack",
      "Elite Valet Pin",
      "Elite Valet Rod",
      "End Caps",
      "End Caps for Leather-Wrapped Cleat Board",
      "Engage Divided Deep Drawer",
      "Engage Divided Shelf",
      "Engage Drawer Face Adapters",
      "Engage Fabric Swatch",
      "Engage Folding Station",
      "Engage Jewelry Organizer",
      "Engage Laundry Organizer",
      "Engage Lingerie Drawer",
      "Engage Pant Organizer",
      "Engage Shoe Organizer",
      "Engage Storage Box",
      "Extra Engage Deep Divider",
      "Extra Engage Lingerie Divider",
      "Extra Engage Shelf Divider",
      "Extra/Replacement Engage Hamper Bag",
      "Extra/Replacement Hamper Bag",
      "Extra/Replacement Hamper Handle",
      "File Hanger",
      "Fishing Rod Holder",
      "Gable Leveler Leg",
      "Garage Leveler Leg",
      "Garden Tool Hook",
      "Garment Clip",
      "Glass Shelf Filler",
      "H-Channel",
      "Hand Tool Hook",
      "Hose Hook",
      'Illuminated Glass Shelf - 14" Deep',
      'Illuminated Glass Shelf - 21" Deep',
      "Leather-Wrapped Cleat Board",
      "Outlet Grommet - Double",
      "Outlet Grommet - Single",
      "Pant Hanger",
      "Paper Organizer Set",
      "Paper Tray",
      "Pencil Tray",
      "Pliers Holder",
      "Pull-out Hamper",
      "Pull-out Pant Rack",
      "Round Grommet",
      "Screw Cover Strip",
      "Screwdriver Holder",
      "Shelf Bracket",
      "Shelf Stiffener",
      "Signature Suspended Center Support",
      "Signature Suspended End Support",
      "Single Hook",
      "Small Tray",
      "Snap Toggler Pan Head Screw",
      'SnapToggler 1/4"',
      "Tie Holder",
      "Tie Hook",
      "Tilt-out Hamper",
      "Track Joiner",
      "Track Panel",
      "Upright Tray",
      "Valet Hook",
      "Waterfall Hook",
      "Wire Basket",
      "Wire Basket Hooks",
      "Wrench Holder",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [row.ClosetName, row.PartName, row.Quantity, row.AccDesc, makeCategory(Categories.Accessories),];
    },
  },
  {
    matchNames: [
      "Tilt-out Hamper Door Flap Stay",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      const newQty = parseFloat(row.Quantity) / 2;
      return [row.ClosetName, row.PartName, newQty, "Pair", makeCategory(Categories.Accessories),];
    },
  },
  {
    matchNames: [
      "Symphony Coat Hook",
      "Symphony Container Tray",
      "Symphony Jewelry Hook",
      "Symphony Leather Pocket",
      "Symphony Mirror Clips",
      "Symphony Paper Tray",
      "Symphony Pencil Holder",
      "Symphony Post Extensions",
      "Symphony Shoe Hook",
      "Symphony Tablet Stand",
      "Symphony Triple Hook",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      const newPartName = row.PartName.split(" - ")[0].trim();
      return [row.ClosetName, newPartName, row.Quantity, row.AccDesc, makeCategory(Categories.Accessories),];
    },
  },
  /**Glides Category */
  {
    matchNames: [
      "Bottom Mount Slides",
      "Side Mount Slides",
      "Side Mount Soft Close Slides",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [row.ClosetName, row.PartName, row.Quantity, row.Width, makeCategory(Categories.Hardware),];
    },
  },
  /**Hardware Category */
  {
    matchNames: [
      "Cleat Board Jig",
      "Drill Bit for Valet Pin Jig",
      //"Euro Screw",
      "Euro Screw with Shoulder",
      "Leveling Leg T-Nut",
      "Mount Jig for Valet Pin",
      "Murphy Bed Hardware Kit",
      "Painted Install Screw",
      //"Screw #6 x 1-1/4 Flat Robertson",
      "Screw #6 x 1/2 Pan Robertson",
      "Screw #8 x 1-1/2 Flat Robertson",
      "Screw #8 x 1-1/4 Pan Robertson",
      "Screw #8 x 2-1/2 Flat Robertson",
      //"Screw #8 x 3/4 Pan Robertson",
      //"Screw #8-32 x 1-3/4 Truss Combination",
      "Screw Cap",
      "Shelf Support Clear",
      //"Shelf Support Metal",
      "Steel Angle L Bracket",
      "Titus Double Dowel",
      //"Titus Screw-in Dowel",
      "Toggler Snapskru",
      "Wall Rail",
      //"Wood Dowel",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [row.ClosetName, row.PartName, row.Quantity, "Each", makeCategory(Categories.Hardware),];
    },
  },
  {
    matchNames: [
      "Suspension Bracket",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [row.ClosetName, helpersCPS.alternateSBLeftRight(), row.Quantity, "Each", makeCategory(Categories.Hardware),];
    },
  },
  /**Hardware with Color Category */
  {
    matchNames: [
      "Angle Bracket Cover",
      "Titus Cam Fitting",
      "Wall Rail Cover",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        row.PartName,
        row.Quantity,
        "Each",
        helpers.getHardwareColorFromWoodColor(row.Color),
        makeCategory(Categories.Hardware),
      ];
    },
  },
  {
    matchNames: [
      "Suspension Bracket Cover",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [
        row.ClosetName,
        helpersCPS.alternateSBCLeftRight(),
        row.Quantity,
        "Each",
        helpers.getHardwareColorFromWoodColor(row.Color),
        makeCategory(Categories.Hardware),
      ];
    },
  },
  /**Hinges Category */
  {
    matchNames: [
      "Corner Unit Hinge",
      "Corner Unit Hinge Plate",
      "Full Overlay Hinge Plate",
      "Full Overlay Regular Hinge",
      "Full Overlay Soft Close Hinge",
      "Half Overlay Hinge Plate",
      "Half Overlay Regular Hinge",
      "Half Overlay Regular Hinge with Plate",
      "Half Overlay Soft Close Hinge",
      "Half Overlay Soft Close Hinge with Plate",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [row.ClosetName, row.PartName, row.Quantity, makeCategory(Categories.Hardware),];
    },
  },
  /**Pulls and Knobs Category */
  {
    matchNames: [
      "Bayfield Modern Knob",
      "Bedford Modern Pull",
      "Critchfield Classic Pull",
      "Dunbar Modern Pull",
      "Elite Classic Aluminum Pull",
      "Elite Classic Handle",
      "Elite Classic Round Knob",
      "Elite Classic Square Knob",
      "Elite Modern Dropped Handle",
      "Elite Modern Handle",
      "Elite Modern Knob",
      "Elite Traditional Round Handle",
      "Elite Traditional Round Knob",
      "Elite Transitional Arched Handle",
      "Elite Transitional Bow Handle",
      "Elite Transitional Dished Knob",
      "Elite Transitional Dropped Handle",
      "Elite Transitional Dropped Pull",
      "Extruded Handle",
      "Garage Handle",
      "Modern Bar Pull",
      "Morton Classic Pull",
      "Volta Classic Knob",
    ],
    outputFunction: ({ row }: OutputFuncParams) => {
      return [row.ClosetName, row.PartName, row.Quantity, row.PartExport, makeCategory(Categories.HandlesAndKnobs),];
    },
  },
];
